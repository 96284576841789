import React from "react";
import { Box } from "@mui/material";
import HelpCards from '../components/HelpCards'
import ConstraintsSection from '../components/ConstraintsSection'
import { Products } from "../components/Products";
import { OneLastThing } from "../components/OneLastThing";
import { Bonuses } from "../components/Bonuses"
import { Questions } from "../components/Questions";
import { LinkedIn } from "../components/LinkedIn";
import { Hero } from "../components/Hero";
import { Footer } from "../components/Footer";
import { Divider } from "../components/Divider";

export const Home = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#111429",
      }}
      >
      <Hero />
      <HelpCards />
      <Divider />
      <ConstraintsSection />
      <Products />
      <Divider />
      <Bonuses />
      <Questions />
      <LinkedIn />
      <OneLastThing />
      <Footer />
    </Box>
  );
}
