import React from 'react';
import { Paper, Typography} from '@mui/material';
import { H2, Content } from '../App'
import { CTAFormUpdated } from './CTAFormUpdated';

export const Questions = () => {  
    return (
        <Content sx={{marginBottom: "150px"}}>
        <H2
        sx={{
            width: "100%",
            marginBottom: "60px",
        }}
        >
        Stop Worrying. Start Getting Offers in 2025.
        </H2>
        <Paper
            sx={{
                maxWidth: "700px",
                padding: {xs: "30px 20px", md: "60px 75px"},
                margin: "60px auto",
                marginTop: "0",
                borderRadius: "10px",
            }}
        >        
        <H2
        sx={{
            textAlign: "left",
            color: "#141730",
            fontSize: { xs: "30px", lg: "60px" },
            margin: "0",
            marginBottom: {xs: "20px", md: "30px"},
            width: "100%",
        }}
        >
          Question for you...
        </H2>
        <Typography
            sx={{
                fontSize: {xs: "1.25rem", md: "1.5rem"},
                textAlign: "center",
            }}
        >
          Do you find yourself struggling to keep up with your job search, starting out with high hopes only to feel lost and discouraged?
          <br /><br />
          Or maybe you second-guess every application, caught up in endless revisions and held back by the fear of not being “good enough”?
          <br /><br />
          Perhaps you’ve stared at countless job boards, feeling overwhelmed by the sheer number of options, unsure where to even begin?
          <br /><br />
          Or maybe you’ve spent time researching “job search tips” and the latest tools, but still feel uncertain about how to put them into action?
          <br /><br />
          If any of this sounds familiar, know that you’re not alone.
          <br /><br />
          In fact, you’re just like the many job seekers we’ve helped—before they joined Tayyablance.
          <br /><br />
          And after going through our structured job search programs, those struggles became a thing of the past.
          <br /><br />
          Some of our members have gone on to secure dream jobs, advance into leadership roles, or pivot to entirely new career paths.
          <br /><br />
          Others have built strong professional networks, improved their online presence, and gained confidence in their ability to land meaningful roles.
          <br /><br />
          And you’re one step away from making the commitment to yourself to finally start your job search with clarity, confidence, and consistency.

        </Typography>
        <CTAFormUpdated
            form_id="7359095"
            buttonText="Send Me Lesson #1"
        />
        
        </Paper>
        </Content>
    );
};
