import React from 'react';
import { Box, Paper, Typography} from '@mui/material';
import { ProductSection } from './ProductSection';
import { H2, Content, H3 } from '../App'

import chat1 from '../assets/images/chats/chat1.png';
import chat2 from '../assets/images/chats/chat2.png';
import chat3 from '../assets/images/chats/chat3.png';
import chat4 from '../assets/images/chats/chat4.png';
import chat5 from '../assets/images/chats/chat5.png';
import chat6 from '../assets/images/chats/chat6.png';
import chat7 from '../assets/images/chats/chat7.png';
import chat8 from '../assets/images/chats/chat8.png';
import chat9 from '../assets/images/chats/chat9.png';
import chat10 from '../assets/images/chats/chat10.png';
import chat11 from '../assets/images/chats/chat11.png';
import chat12 from '../assets/images/chats/chat12.png';
import { Grid } from '@mui/system';

const ChatBlock = ({img1, img2, quote}) => {
  return (
    <Box
      sx={{
        width: {xs: "90%", md: "80%"},
        margin: "50px auto",
        marginTop: "0 !important",
      }}
    >
      <Box>
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "'Inter', sans-serif !important",
            width: "80%",
            margin: "30px auto",
            marginBottom: "50px",
          }}
          dangerouslySetInnerHTML={{ __html: quote }}
        >
        </Typography>
      </Box>
      <Grid
        container
        spacing={4}
        sx={{
          marginBottom: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} md={6}>
          <img src={img1} width={"100%"}/>
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={img2} width={"100%"}/>
        </Grid>
      </Grid>
    </Box>
  );
}

export const Chats = () => {
  const images = [
    `${chat1}`,
    `${chat2}`,
    `${chat3}`,
    `${chat4}`,
    `${chat5}`,
    `${chat6}`,
    `${chat7}`,
    `${chat8}`,
    `${chat9}`,
    `${chat10}`,
    `${chat11}`,
    `${chat12}`,
  ];
  
  return (
    <Content sx={{ marginBottom: "150px" }}>
      <H2
      sx={{
        width: "100%",
        marginBottom: "60px",
      }}
      >
      What Our Students Are Saying
      </H2>
      <Paper
      sx={{
        paddingTop: "30px",
        backgroundColor: "white",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
      >
      <H3
      sx={{
        color: "#141730",
        fontSize: { xs: "24px", lg: "30px" },
        margin: {xs: "30px 0 !important", md: "30px 0"},
        width: "80%",
      }}
      >
      Real feedback from real students. Our personalized support has helped countless students improve their academic skills and boost their confidence. See the impact we’ve made through their own words.
      <br />
      </H3>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontWeight: "bold",
          marginBottom: 2,
          textAlign: "center",
          margin: "0 auto",
          width: "80%"
        }}
        >
        Take a look at some of the conversations we’ve had with students about their progress and success.
      </Typography>
      <ChatBlock
        img1 = {images[0]}
        img2 = {images[1]}
        quote = "“The feedback I received on my essays was a game-changer! The detailed comments not only helped me understand my mistakes but also taught me how to structure my arguments better. With their guidance, I went from a B average to consistent A's. I feel so much more confident in my writing now!” <br /> <b>Maria P.</b>"
      />
      <ChatBlock
        img1 = {images[2]}
        img2 = {images[3]}
        quote = "“I was struggling with my thesis, unsure if I was on the right track. The academic support team reviewed my draft and provided constructive feedback that highlighted both my strengths and areas for improvement. Their suggestions helped me refine my work, and I ended up getting a distinction. Their support gave me the boost I needed!”<br /> <b>James L.</b>"
      />
      <ChatBlock
        img1 = {images[4]}
        img2 = {images[5]}
        quote = "“I used to dread submitting assignments because I always doubted my work. After getting feedback from this service, I learned how to approach academic writing step by step. The confidence I’ve gained from knowing my work is strong has been invaluable. My professors have noticed the improvement too!”<br />P<b>riya R.</b>"
      />
      <ChatBlock
        img1 = {images[6]}
        img2 = {images[7]}
        quote = "“The feedback service didn’t just improve my grades—it changed how I approach my studies. The constructive criticism was easy to understand and implement, and I finally feel like I’m in control of my academic performance. I scored my first A+ this semester thanks to their help!”<br /> <b>Tom S.</b>"
      />
      <ChatBlock
        img1 = {images[8]}
        img2 = {images[9]}
        quote = "“I was always second-guessing myself until I started using this academic feedback service. The suggestions were clear, practical, and tailored to my needs. It’s like having a personal coach! I’ve seen a significant improvement in my grades, and I feel more prepared than ever to tackle future assignments.”<br /> <b>Anya K.</b>"
      />
      <ChatBlock
        img1 = {images[10]}
        img2 = {images[11]}
        quote = "“I can’t thank this feedback service enough! The personalized comments on my assignments helped me identify where I was losing marks and how to fix it. Their encouragement and clear guidance have completely transformed my approach to studying. I’m now achieving grades I never thought were possible, and my confidence has skyrocketed!”<br /> <b>Liam H.</b>"
      />
      </Paper>
    </Content>
  );
};
