import React from "react";
import { Container } from "@mui/material";
import './App.css';
import { styled } from '@mui/system';
import { Home } from "./pages/Home";
import { BoostPage } from "./pages/BoostPage";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

export const H1 = styled('h1')(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "2.5em !important",
    width: "80%",
    margin: "80px auto 0 auto",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "3.25em !important",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "4em",
    width: "70%",
    marginTop: "100px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "4em",
    width: "100%",
    marginTop: "120px",
  },
  color: "#FFF",
  textAlign: "center",
  fontWeight: "800",
  lineHeight: "1.25em",
}));


export const H2 = styled('h2')(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "1.75em",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "2.3em",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.75em",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "3em",
  },
  color: "#FFF",
  textAlign: "center",
  fontWeight: "800",
  lineHeight: "1.3em",
  marginTop: "150px",
  width: "80%",
  margin: "1.5rem auto",
}));

export const H3 = styled('h3')(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.25rem", // Font size for small screens and up
    width: "80%",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.35rem", // Font size for medium screens and up
    width: "70%",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.5rem", // Font size for medium screens and up
    width: "60%",
  },
  color: "#FFF",
  textAlign: "center",
  fontWeight: "400",
  lineHeight: "1.5em",
  marginTop: "150px",
  margin: "1.75rem auto",
}));


export const Content = ({sx, children}) =>{
  return(
    <Container
      sx={{
        ...sx,
        maxWidth: "1200px"
      }}
    >
      {children}
    </Container>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/boost-your-grades" element={<BoostPage />} />
      </Routes>
    </Router>
  );
}

export default App;
