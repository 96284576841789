import React from 'react';
import { Box, Typography} from '@mui/material';

export const Divider = () =>{
  return(
    <Box
      sx={{
        backgroundColor: "#dfe8ff",
        width: {xs: "90%", md: "70%"},
        height: "5px",
        margin: {xs: "40px auto", md: "80px auto"},
      }}
    >
    </Box>
  );
}

