import React, { useState } from "react";
import axios from "axios";
import { 
  Box, 
  TextField, 
  Button, 
  CircularProgress, 
  Alert 
} from "@mui/material";

// Reusable Section Component
export const CTAFormUpdated = ({ formId, buttonText, width = "80%", sx }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("success"); // Can be "success" or "error"
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setIsLoading(true);
    setMessage("");

    try {
      const response = await axios.post(
        `https://app.kit.com/forms/${formId}/subscriptions`,
        { email_address: email },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded", // Ensure the data is sent correctly
          },
        }
      );

      if (response.status === 200) {
        setMessageType("success");
        setMessage("Success! Now check your email to confirm your subscription.");
      }
    } catch (error) {
      setMessageType("error");
      setMessage("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        padding: 3,
        width: {width},
        margin: "0 auto",
        fontFamily: "Arial, sans-serif",
        ...sx,
      }}
    >
      {message && (
        <Alert severity={messageType} sx={{ mb: 2 }}>
          {message}
        </Alert>
      )}
      <TextField
        fullWidth
        label="Email Address"
        variant="outlined"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        sx={{ mb: 3 }}
      />
      <Button
        variant="contained"
        type="submit"
        disabled={isLoading}
        sx={{
          backgroundColor: "#d31652",
          transition: "color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease",
          boxShadow: "none",
          color: "#fff",
          fontSize: "20px",
          display: "flex",
          borderRadius: "0.75rem !important",
          width: "100%",
          fontFamily: "Inter, sans-serif",
          "&:hover": {
            backgroundColor: "#B82C3F",
          }
        }}
      >          
        {isLoading ? <CircularProgress size={24} sx={{ color: "white" }} /> : buttonText}
      </Button>
    </Box>
  );
};
