import React from "react";
import { Container, Typography, Grid, Card, Box } from "@mui/material";
import RedRing from '../assets/images/red-ring.png';
import { H2 } from '../App'


const ConstraintsSection = () => {
  return (
    <Container maxWidth="lg" sx={{ marginBottom: "150px", marginTop: {xs: "100px", md: "150px"} }}>
      {/* Section Title */}
      <H2
        sx={{
          width: "100%",
          marginBottom: "60px",
        }}
      >
        The structure you need to finally start your job search with consistency and confidence…
      </H2>

      {/* Main Card */}
      <Card
        sx={{
          backgroundColor: "white",
          borderRadius: "10px",
          width: "80%",
          maxWidth: "750px",
          padding: 4,
          margin: "30px auto",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* First Heading */}
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", marginBottom: 2 }}
        >
          All job seekers face the same 10 challenges when starting their job
          search journey:
        </Typography>

        {/* First Set of Columns */}
        <Grid container spacing={4} sx={{ marginBottom: 4 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" sx={{ lineHeight: 1.8, fontFamily: "Inter, sans-serif !important" }}>
              If you’re here, chances are one (or more) of these issues has been
              holding you back and keeping you from securing that next role.
              <br />
              And chances are, you know you should be focusing on your job
              search—that’s why you’re here.
              <br />
              Whether it’s finding your dream job, advancing your career, or
              landing your first position after graduation, starting your job
              search has been on your list for a while.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx = {{
                minHeight: "300px",
                maxWidth: "350px",
                padding: "50px 0 0 50px",
                backgroundImage: `linear-gradient(#fff6, #fff6), url(${RedRing})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "0 0, 50%",
                backgroundSize: "auto, 100% 100%",
                transform: "rotate(2deg)",
              }}
            >
              <Typography variant="body1" sx={{ lineHeight: 1.8, fontWeight: "600", fontFamily: "Inter, sans-serif" }}>
                • Lack of clarity on where to start <br />
                • Writing effective resumes <br />
                • Overthinking applications <br />
                • Networking effectively <br />
                • Finding time to job search <br />
                • Overcoming procrastination <br />
                • Building self-confidence <br />
                • Standing out from other candidates <br />
                • Optimizing LinkedIn <br />
                • Staying organized
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Second Heading */}
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", marginBottom: 2 }}
        >
          The accountability and structure you’ve been missing.
        </Typography>

        {/* Second Set of Columns */}
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" sx={{ lineHeight: 1.8, fontFamily: "Inter, sans-serif !important" }}>
              Maybe you’ve tried searching for a job before. But, time and time
              again, life gets in the way. What starts out as a motivated search
              becomes overwhelming, and before you know it, you’re back to
              square one.
              <br />
              But that’s because you lacked guidance and accountability.
              <br />
              You tried to go it alone, almost setting yourself up for
              frustration.
              <br />
              The 30-Day Job Search Challenge changes that. You’ll be guided
              step-by-step, working alongside other job seekers with the same
              goal as you: to search consistently, confidently, and effectively.
              <br />
              This creates the accountability you need to finally commit and
              keep moving forward—no more stalling, no more setbacks.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundImage: "url(https://cdn.prod.website-files.com/6074684e0f60da436ece7ffc/6074b359e96e17c055fb715e_stickynote.png)",
                backgroundPosition: "50%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                justifyContent: "center",
                maxWidth: "280px",
                alignItems: "center",
                padding: "50px 10% 50px",
                display: "flex",
                transform: "rotate(-2deg)",
                paddingBottom: {xs: "100px", md: "50px"},
              }}
            >
              <Typography
                variant="h3"
                sx={{ lineHeight: "1.5em", fontWeight: "400" }}
              >
                Your dream job is
                <span
                  style={{
                    color: "#d31652",
                    backgroundColor: "#ffdcb6",
                    margin: "0 3px",
                    padding: "0 3px",
                    fontWeight: "500"
                  }}
                >
                  closer than you think
                </span>
                <br />
                <br />
                <br />
                it just takes the
                <span
                  style={{
                    color: "#6955ff",
                    backgroundColor: "#9cffc6",
                    padding: "0 3px",
                    margin: "0 3px",
                    fontWeight: "500"
                  }}
                >
                  right steps
                </span>
                , one day at a time.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default ConstraintsSection;
