import React from 'react';
import { Box, Typography} from '@mui/material';
import { ProductSection } from './ProductSection';
import { H2, Content } from '../App'
import cvFormat from '../assets/images/updated/image04.png'
import gptCourse from '../assets/images/updated/image05.png'

export const Bonuses = () => {
  const bonuses = [
    {
      imageSrc: `${cvFormat}`,
      heading: '[BONUS #1] Access to 2 ATS-Friendly CV Formats ($100 Value)',
      content:
        `
          Get immediate access to two Applicant Tracking System (ATS)-friendly CV formats, crafted to maximize your chances of getting noticed in today’s competitive job market. These templates are:<br/ ><br/ >
          • <strong>ATS-Optimized:</strong> These CV formats are specifically designed to pass through ATS filters, which means your resume reaches human eyes instead of getting filtered out.<br/ ><br/ >
          • <strong>Professional & Customizable:</strong> Each template is structured to showcase your skills, experience, and achievements in a clear, impactful way. They’re easily customizable, so you can tailor each application to specific roles and stand out with every submission.<br/ ><br/ >
          • <strong>Proven to Increase Responses:</strong> With layouts focused on clarity and content hierarchy, these formats make it easier for recruiters to recognize your value, resulting in higher response rates from potential employers.
        `,
      bulletPoints: [],
    },
    {
      imageSrc: `${gptCourse}`,
      heading: '[BONUS #2] Exclusive ChatGPT Prompts for Job Seekers ($100 Value)',
      content:
        `
          Navigate every aspect of your job search with ease using our collection of ChatGPT prompts, created specifically for job seekers to save time, improve communication, and add a personal touch to each interaction. With these prompts, you’ll be able to:<br/ ><br/ >
          • <strong>Craft Impressive Cover Letters and Resumes:</strong> Get assistance in creating tailored cover letters and resume sections that highlight your skills and experience, making a strong first impression.<br/ ><br/ >
          • <strong>Optimize Your LinkedIn Profile:</strong> Use prompts designed to help you build an attention-grabbing headline, write a compelling summary, and describe your experience in a way that appeals to recruiters and hiring managers.<br/ ><br/ >
          • <strong>Prepare for Interviews with Confidence:</strong> Access prompts that help you develop answers to common interview questions, structure responses for challenging queries, and showcase your unique strengths.<br/ ><br/ >
          • <strong>Send Effective Networking Messages and Follow-ups:</strong> With ready-to-use prompts for networking emails and follow-ups, you can confidently reach out to industry professionals, ask for informational interviews, and maintain meaningful connections throughout your job search.<br/ ><br/ >

        `,
      bulletPoints: [],
    },
  ];
  
  return (
    <Content sx={{marginBottom: "150px"}}>
      <H2
        sx={{
          width: "100%",
        }}
      >
        Tayyablance Bonuses!
      </H2>
      <Typography
        content='body1'
        sx={{
          width: "100% !important",
          color: "#FFF",
          maxWidth: "900px",
          margin: "10px auto",
          marginBottom: "60px",
          textAlign: "center",
          fontSize: {xs:"18px", md: "26px"}

        }}
      >
        Over the past few years, we have helped countless job seekers streamline their job search process, stand out in competitive markets, and land their dream roles.
        <br /><br />
        And through this journey, we've discovered that a few additional tools make all the difference in boosting confidence and success for job seekers.
        <br /><br />
        So, we decided to include these bonuses in Tayyablance… completely free!

      </Typography>
      <Box>
        {bonuses.map((section, index) => (
          <ProductSection
            key={index}
            imageSrc={section.imageSrc}
            heading={section.heading}
            content={section.content}
            bulletPoints={section.bulletPoints}
            imageRight={index % 2 === 0} // Alternate image position
            type="bonus"
          />
        ))}
      </Box>
    </Content>
  );
};
