import React from "react";
import { Container, Typography, Grid, Card, Box } from "@mui/material";
import RedRing from '../assets/images/red-ring.png';
import { H2 } from '../App'


export const Challenges = () => {
  return (
    <Container maxWidth="lg" sx={{ marginBottom: "150px" }}>
      {/* Section Title */}
      <H2
        sx={{
          width: "100%",
          marginBottom:"40px",
        }}
      >
        The Challenges Keeping You from Academic Success
      </H2>

      {/* Main Card */}
      <Card
        sx={{
          backgroundColor: "white",
          borderRadius: "10px",
          width: "80%",
          maxWidth: "750px",
          padding: 4,
          margin: "30px auto",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* First Heading */}
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", marginBottom: 2 }}
        >
          All students face similar obstacles when it comes to achieving top grades.
        </Typography>

        {/* First Set of Columns */}
        <Grid container spacing={4} sx={{ marginBottom: 4 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" sx={{ lineHeight: 1.8, fontFamily: "Inter, sans-serif !important" }}>
              If you’re here, it’s likely one (or more) of these struggles has been holding you back from reaching your full academic potential.
              <br /><br/>
              And if you're reading this, you probably already know you need support—that’s why you’re on this page in the first place.
              <br /><br/>
              Whether it’s mastering academic writing, understanding complex referencing, or overcoming procrastination, you’ve likely been wanting to improve your academic skills for a while.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx = {{
                minHeight: "300px",
                maxWidth: "350px",
                padding: "50px 0 0 50px",
                backgroundImage: `linear-gradient(#fff6, #fff6), url(${RedRing})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "0 0, 50%",
                backgroundSize: "auto, 100% 100%",
                transform: "rotate(2deg)",
              }}
            >
              <Typography variant="body1" sx={{ lineHeight: 1.8, fontWeight: "600", fontFamily: "Inter, sans-serif" }}>
                • Struggling to structure essays <br />
                • Confusion about referencing and citations<br />
                • Difficulty avoiding plagiarism <br />
                • Time management issues <br />
                • Procrastination and distractions <br />
                • Low confidence in writing skills <br />
                • Lack of feedback on assignments <br />
                • Unclear about academic expectations
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Second Heading */}
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", marginBottom: 2 }}
        >
          The Support You’ve Been Missing
        </Typography>

        {/* Second Set of Columns */}
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" sx={{ lineHeight: 1.8, fontFamily: "Inter, sans-serif !important" }}>
            Maybe you’ve tried studying on your own or following generic online resources. But time after time, the same challenges come up, and what started as motivation turns to frustration. Before you know it, you’re feeling stuck again.
            <br /><br />
            That’s because you’ve been going it alone, without the expert guidance you need.
            <br /><br />
            Our Academic Support Services change that. You’ll have structured guidance, personalized feedback, and targeted resources designed to help you build skills consistently and effectively.
            <br /><br />
            This creates support and accountability like nothing you've ever experienced.

            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundImage: "url(https://cdn.prod.website-files.com/6074684e0f60da436ece7ffc/6074b359e96e17c055fb715e_stickynote.png)",
                backgroundPosition: "50%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                justifyContent: "center",
                maxWidth: "280px",
                alignItems: "center",
                padding: "50px 10% 50px",
                display: "flex",
                transform: "rotate(-2deg)",
                paddingBottom: {xs: "100px", md: "50px"},
              }}
            >
              <Typography
                variant="h3"
                sx={{ lineHeight: "1.5em", fontWeight: "400" }}
              >
                Academic success
                <span
                  style={{
                    color: "#d31652",
                    backgroundColor: "#ffdcb6",
                    margin: "0 3px",
                    padding: "0 3px",
                    fontWeight: "500"
                  }}
                >
                  isn’t solo
                </span>
                <br />
                <br />
                <br />
                  —it’s about having the
                <span
                  style={{
                    color: "#6955ff",
                    backgroundColor: "#9cffc6",
                    padding: "0 3px",
                    margin: "0 3px",
                    fontWeight: "500"
                  }}
                >
                  right support
                </span>
                by your side
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};
