import React from "react";
import { Box, Card, Typography, Grid } from "@mui/material";
import { H2 } from "../App";
import { useNavigate } from "react-router-dom";

const cards = [
  {
    emoji: "💼",
    title: "Land Your Dream Job",
    content:
      "Step-by-step strategies to help you structure your job search, stay focused, and secure the role you’ve been aiming for.",
    link: "#",
  },
  {
    emoji: "📄",
    title: "Create an ATS-Friendly CV",
    content:
      "Learn how to craft a CV that stands out, passes Applicant Tracking Systems, and showcases your strengths to recruiters.",
    link: "#",
  },
  {
    emoji: "🔗",
    title: "Optimize Your LinkedIn Profile",
    content:
      "Elevate your LinkedIn presence with proven tips to boost visibility, attract recruiters, and build a professional network.",
    link: "#",
  },
  {
    emoji: "📚",
    title: "Boost Your Grades",
    content:
      "Master academic writing with guidance on structuring essays, referencing correctly, and developing clear, persuasive arguments to achieve top results.",
    link: "/boost-your-grades",
  },
];

const HelpCards = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        margin: "50px 0",
        py: 6,
        px: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* Section Heading */}
      <H2
        sx={{
          color: "white",
          fontWeight: "bold",
        }}
      >
        How can I help you?
      </H2>

      {/* Cards */}
      <Grid container spacing={3} maxWidth="lg">
        {cards.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              sx={{
                backgroundColor: "#2d335d",
                color: "white",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                borderRadius: 2,
                p: 2,
                cursor: "pointer",
              }}
              onClick={() => navigate("/boost-your-grades")}
            >
              <Typography
                variant="h5"
                component="div"
                sx={{ mb: 2, textAlign: "left" }}
                aria-label="emoji"
              >
                {card.emoji}
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                sx={{ fontFamily: "Inter, sans-serif", fontWeight: "bold", mb: 1 }}
              >
                {card.title}
              </Typography>
              <Typography
                sx={{ fontFamily: "Inter, sans-serif",}}
                variant="body2"
              >
                {card.content}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HelpCards;
