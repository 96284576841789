import React from 'react';
import { Paper, Typography} from '@mui/material';
import { H2, Content } from '../App'
import { CTAFormUpdated } from './CTAFormUpdated';

export const OneLastThing = () => {  
  return (
    <Content>
      <H2
        sx={{
          width: "100%",
          marginBottom: "60px"
        }}
      >
        Stop Overthinking. Start Landing Offers in 2025.
      </H2>
      <Paper
        sx={{
            maxWidth: "1050px",
            padding: {xs: "30px 20px", md: "60px 75px"},
            marginY: "60px",
            borderRadius: "10px",
        }}
      >
        <H2
          sx={{
            textAlign: "left",
            color: "#141730",
            fontSize: { xs: "30px", lg: "60px" },
            margin: "0",
            marginBottom: {xs: "20px", md: "30px"},
            width: "100%",
          }}
        >
          One Last Thought…
        </H2>
        <Typography
          sx={{
            fontSize: {xs: "1.25rem", md: "1.5rem"},
            textAlign: "center",
          }}
        >
          If you’re still reading, you might be wondering… is this really worth it?
          <br /><br />
          Sure, you could piece together all the free job search advice floating around online, pulling tips from countless articles, videos, and forums. And you could use all of that to try to land a role on your own.
          <br /><br />
          But if you’re here, reading this far, I’m guessing you’ve tried that route before.
          <br /><br />
          And the results… well… they haven’t exactly matched your hopes.
          <br /><br />
          Since “find a new job” or “make a career change” first hit your to-do list (likely quite a while ago), you’ve probably seen others find success, secure new positions, and advance their careers in ways you aspire to.
          <br /><br />
          And here we are. 2024 has flown by, and the opportunity for a fresh start is just around the corner.
          <br /><br />
          Now, imagine this: looking back at the end of 2025 and pinpointing this very moment—this exact decision—as the turning point that led to an incredible, life-changing year.
          <br /><br />
          A new career, a dream job, meaningful connections, a completely new professional path. All of this is possible, and it can start with the simple, consistent steps of a guided job search.
          <br /><br />
          And it all starts right here, right now.
          <br /><br />
          <strong>
            So, what do you say?
          </strong>
          <br /><br />
          "Imagine a year from now, looking back and knowing that this decision—joining Tayyablance—was the moment that changed everything. A new career, dream job, or fresh start is within reach. You’ve got the guidance, accountability, and tools. All that’s left is to take the first step."
        </Typography>
        <CTAFormUpdated
          formId="7359095"
          buttonText="Send me Lesson #1"
          sx={{width: {xs: '80%', sm: '60%'}}}
        />
        
      </Paper>
    </Content>
  );
};
