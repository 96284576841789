import React from "react";
import { Typography } from "@mui/material";
import { ShowCase } from './Showcase';
import { H3 } from "../App";
import proof1 from '../assets/images/proof1.jpg';
import proof2 from '../assets/images/proof2.jpg';
import proof3 from '../assets/images/proof3.jpg';
import proof4 from '../assets/images/proof4.jpg';
import proof5 from '../assets/images/proof5.jpg';
import proof6 from '../assets/images/proof6.jpg';
import proof7 from '../assets/images/proof7.jpg';
import proof8 from '../assets/images/proof8.jpg';
import proof9 from '../assets/images/proof9.jpg';
import proof10 from '../assets/images/proof10.jpg';
import proof11 from '../assets/images/proof11.png';
import proof12 from '../assets/images/proof12.png';

export const LinkedIn = () => {
  const images = [
    `${proof12}`,
    `${proof11}`,
    `${proof1}`,
    `${proof2}`,
    `${proof3}`,
    `${proof4}`,
    `${proof5}`,
    `${proof6}`,
    `${proof7}`,
    `${proof8}`,
    `${proof9}`,
    `${proof10}`,
  ];
  
  return (
    <ShowCase
      heading="Real Engagement, Real Results"
      images={images}
    >
      <Typography
        sx={{
          fontSize: {xs: "1.25rem", md: "1.5rem"},
          textAlign: "center",
          width: {xs: "95%", md: "80%"},
          marginBottom: "50px",
        }}
      >
        As a career strategist on LinkedIn with consistent engagement and reach, I’m bringing the strategies that work directly to you. Here’s a glimpse of the professional engagement that you can achieve, too, through optimized LinkedIn practices.
      </Typography>
    </ShowCase>
  );
};
