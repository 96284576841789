import React from 'react';
import { Box, Link as MuiLink } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import { H1, H2, H3, Content } from '../App';

import heroBg from '../assets/images/hero-bg.png';
import logo from '../assets/images/logo.png';

export const Hero =() => {
  const navigate = useNavigate();
  return(
    <Box
      sx={{
        top: "0",
        position: "relative",
        backgroundColor: "#252a4e",
        backgroundImage: `
        linear-gradient(to bottom, #14173066, #252a4e), 
        url(${heroBg}), 
        linear-gradient(to bottom, #141730, #252a4e)
        `,
        width: "100%",
        paddingBottom: "50px",
        minHeight: "calc(100vh - 50px)",
        backgroundPosition: "0 0, 50% 0, 0 0",
        backgroundSize: "auto, 500px, auto",
      }}
    >
      {/* Navigation */}
      <Box
        sx={{
          zIndex: "1000",
          width: "100%",
          padding: "6px 0",
          display: "flex",
          flexDirection: "row",
          backgroundColor: "rgba(17, 20, 41, 1)",
          justifyContent: "space-between",
          position: "fixed",
        }}
      >
        <Content>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                paddingLeft: {xs: "50px", md: "60px"},
                height: {xs: "40px", md: "50px"},
                backgroundImage: `url(${logo})`,
                backgroundSize: {xs: "40px", md: "50px"},
                backgroundRepeat: "no-repeat",
                display: "flex",
                alignItems: "center",
                color: "#FFF",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
            </Box>
            <Box sx={{display: "flex"}}>
              <Box sx={{marginRight: {xs: "5px", md: "10px"}}}>
                <Link
                  to="/"
                  style={{
                    backgroundColor: "#d31652",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: {xs: "6px !important", md: "1.25rem"},
                    lineHeight: "1.35rem",
                    padding: "10px 20px",
                    color: "#FFF",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  Land a Job
                </Link>
              </Box>
              <Link
                to="/boost-your-grades"
                style={{
                  backgroundColor: "#d31652",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: {xs: "6px !important", md: "1.25rem"},
                  lineHeight: "1.35rem",
                  padding: "10px 20px",
                  color: "#FFF",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Boost Your Grades
              </Link>
            </Box>
          </Box>
        </Content>

      </Box>
      <Content
        sx={{
          paddingTop: "120px !important",
        }}
      >
        <H1
          sx={{
            marginTop: "0 !important"
          }}
        >
          Turn Applications into Job Offers in Just 30 Days
        </H1>
        <H3 sx={{fontSize:{xs: "1.25em !important",md: "1.5em !important",lg: "1.75em !important", width: "80% !important"}, margin: "2em auto 20px !important"}}>
          Our 30-Day Job Search Strategies Course has already empowered countless job seekers with essential skills and strategies for landing their dream roles.
        </H3>
        <H3 sx={{fontSize:{xs: "1.25em !important",md: "1.5em !important",lg: "1.75em !important", width: "80% !important"}, margin: "2em auto 50px !important"}}>
          But unlike other job search guides, this course isn’t just theory – you'll apply what you learn each day, build real connections, and finish with a tailored job search toolkit ready to drive your success.
        </H3>

        <MuiLink
          sx={{
            display: "flex",
            width: {xs: "80%", md: "400px"},
            padding: "18px 30px",
            textDecoration: "none",
            color: "white",
            borderRadius: "5px",
            backgroundImage: "linear-gradient(to right, #d31652, #ff8400)",
            margin: "0 auto",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "500",
            cursor: "pointer",
            lineHeight: "1.3em",
            fontSize: {xs: "24px", md: "30px"}
          }}
          href="https://wa.me/447859508266"
          target="_blank"
        >
          Get Support
        </MuiLink>
        <H2 sx={{width: "100% !important", marginY: "2em", marginTop: "50px !important"}}>
          No more guesswork.<br />
          <em>Start</em> your job search with confidence in 2025.
        </H2>
      </Content>

    </Box>
  );
}
