import React from 'react';
import { Link, Paper, Box} from '@mui/material';
import { ProductSection } from './ProductSection';
import { H2, Content } from '../App'
import whatsapp from '../assets/images/whatsapp.png';
import gmail from '../assets/images/gmail.svg';


export const Footer = () => {

  return (
    <Paper
      sx={{
        // marginTop: "50px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
        width: "80%",
        maxWidth: "1200px",
        backgroundColor: "#111429",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: "50px"
      }}
    >
      <Box
        sx={{
          marginBottom: {md: "10px", md: "30px"},
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx= {{
            backgroundImage: `url(${whatsapp})`,
            marginRight: "10px",
            width: {xs: "30px", md: "40px"},
            height: {xs: "30px", md: "40px"},
            backgroundSize: {xs: "30px", md: "40px"},
          }}
        >

        </Box>
        <Link
          sx={{
            backgroundRepeat: "no-repeat",
            fontSize: {xs: "16px", md: "30px"},
            height: "50px",
            lineHeight: "50px",
            backgroundSize: "40px",
            color: "#FFFFFF",
            textDecoration: "none",
          }}
          href="https://wa.me/447859508266"
          target="_blank"
        >
          Call me: +447859508266
        </Link>
      </Box>
      <Box
        sx={{
          marginBottom: {md: "10px", md: "30px"},
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx= {{
            backgroundImage: `url(${gmail})`,
            marginRight: "10px",
            width: {xs: "30px", md: "40px"},
            height: {xs: "30px", md: "40px"},
            backgroundSize: {xs: "30px", md: "40px"},
          }}
        >

        </Box>
        <Link
          sx={{
            backgroundRepeat: "no-repeat",
            fontSize: {xs: "16px", md: "30px"},
            height: "50px",
            lineHeight: "50px",
            backgroundSize: "40px",
            color: "#FFFFFF",
            textDecoration: "none",
          }}
          href="mail:tayyabch.16@gmail.com"
          target="_blank"
        >
          mail me: tayyabch.16@gmail.com
        </Link>
      </Box>
      <Box
        sx={{
          marginBottom: {md: "10px", md: "30px"},
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx= {{
            backgroundImage: `url(${whatsapp})`,
            marginRight: "10px",
            width: {xs: "30px", md: "40px"},
            height: {xs: "30px", md: "40px"},
            backgroundSize: {xs: "30px", md: "40px"},
          }}
        >

        </Box>
        <Link
          sx={{
            backgroundRepeat: "no-repeat",
            fontSize: {xs: "16px", md: "30px"},
            height: "50px",
            lineHeight: "50px",
            backgroundSize: "40px",
            color: "#FFFFFF",
            textDecoration: "none",
          }}
          href="https://wa.me/447859508266"
          target="_blank"
        >
          Get in Touch
        </Link>
      </Box>

    </Paper>
  );
};
