import React from 'react';
import { Box} from '@mui/material';
import { ProductSection } from './ProductSection';
import { H2, H3, Content } from '../App'
import jobSearch from '../assets/images/updated/image01.png'
import cvCourse from '../assets/images/updated/image02.png'
import linkedInCourse from '../assets/images/updated/image03.png'

export const Products = () => {
  const sections = [
    {
      imageSrc: `${jobSearch}`,
      formId: "7359095",
      heading: '💼 The 15-Day Job Search Strategies Course',
      content:
        `
          Our 15-Day Job Search Strategies Course is a self-paced, daily email guide, offered 100% free, teaching you:
          <br /><br />
          <b>• Networking Essentials –</b> Proven strategies to expand your network and create meaningful connections.
          <br /><br />
          <b>• LinkedIn Mastery –</b> Tactics to boost your visibility, attract recruiters, and use LinkedIn effectively.
          <br /><br />
          <b>• Interview Confidence –</b> Insider tips to ace interviews and make a lasting impression.
          <br /><br />
          <b>• Outreach Templates –</b> Ready-to-use templates for networking emails, follow-ups, and informational interviews.
          <br /><br />
          <b>• Job Application Tracking –</b> Methods to stay organized and monitor progress for each role.
          <br /><br />
          <b>• Personal Branding –</b> Build an online presence to stand out from other applicants.
          <br /><br />
          <b>• Time Management for Job Seekers –</b> Techniques to structure your search effectively and avoid burnout.
          <br /><br />
          And so much more!
          <br /><br />
          We’re committed to making job search success accessible for everyone, so this course is entirely free—no strings attached. Join us and start building your future today!
        `,
      bulletPoints: [],
    },
    {
      imageSrc: `${cvCourse}`,
      formId: "7413867",
      heading: '📄 The 7-Day CV Building Email Course',
      content: `
        Our 7-Day CV Building Email Course is a detailed, daily email guide, offered completely free to help you craft a professional CV:
        <br /><br />
        <b>• Impactful Structuring –</b> Learn to format your CV to highlight your key skills and experience effectively.
        <br /><br />
        <b>• ATS-Friendly Tips –</b> Get tips on making your CV stand out to Applicant Tracking Systems and recruiters.
        <br /><br />
        <b>• Keyword Optimization –</b> Discover how to use relevant keywords to get noticed by hiring managers.
        <br /><br />
        <b>• Accomplishment-Based Content –</b> Showcase quantifiable achievements to make a powerful impression.
        <br /><br />
        <b>• Professional Wording –</b> Use precise, professional language that conveys your value.
        <br /><br />
        <b>• Customization Tactics –</b> Adapt your CV to different roles for better alignment with job requirements.
        <br /><br />
        <b>• Design Essentials –</b> Tips on layout and design to make your CV visually appealing and easy to read.
        <br /><br />
        And so much more!
        <br /><br />
        This course is designed to empower job seekers at any stage, and we’re offering it 100% free. Start today and make your CV truly stand out!
      `,
      bulletPoints: [],
    },
    {
      imageSrc: `${linkedInCourse}`,
      formId: "7413879",
      heading: '🔗 The 7-Day LinkedIn Profile Optimization Email Course',
      content: `
        Our 7-Day LinkedIn Profile Optimization Course is a focused, daily email guide, provided for free to elevate your LinkedIn profile:
        <br /><br />
        • <b>Profile Optimization – </b> Craft a headline and summary that grab attention and convey your expertise.
        <br /><br />
        • <b>Experience Highlights – </b> Structure your experience to highlight relevant skills and achievements.
        <br /><br />
        • <b>Keyword Strategy – </b> Boost profile visibility by using industry-specific keywords.
        <br /><br />
        • <b>Network-Building Techniques – </b> Tips on connecting with professionals and expanding your network.
        <br /><br />
        • <b>Engagement Best Practices – </b> Learn how to interact with content and attract attention from recruiters.
        <br /><br />
        • <b>Endorsement and Recommendations – </b> Leverage these features to strengthen your profile credibility.
        <br /><br />
        • <b>Job Search on LinkedIn – </b> Techniques for using LinkedIn's tools and features to aid in your job search.
        <br /><br />
        And so much more!
        <br /><br />
        Our goal is to help job seekers unlock LinkedIn’s full potential, and this course is entirely free. Start today and make LinkedIn work for you!
      `,
      bulletPoints: [
      ],
    },
  ];
  
  return (
    <Content sx={{marginBottom: "150px"}}>
      <H2
        sx={{
          width: "100%",
        }}
      >
        Ready to <em>finally</em> start your job search <br /> the right way?

      </H2>
      <H3
        sx={{
          marginBottom: "60px !important",
          width: "100% !important"
        }}
      >
        Here's a full overview of everything you'e going to get instant access to once you hop aboard TayyabLance...
      </H3>
      <Box>
        {sections.map((section, index) => (
          <ProductSection
            key={index}
            imageSrc={section.imageSrc}
            heading={section.heading}
            content={section.content}
            bulletPoints={section.bulletPoints}
            imageRight={index % 2 === 0} // Alternate image position
            type="product"
            free={true}
            formIdInput= {section.formId}
          />
        ))}
      </Box>
    </Content>
  );
};
