import React from 'react';
import { Box, Grid, Typography, Paper } from '@mui/material';
import { CTAFormUpdated } from './CTAFormUpdated';
import { H2 } from '../App';
import freeStamp from '../assets/images/freeStamp.png';

// Reusable Section Component
export const ProductSection = ({ imageSrc, heading, content, bulletPoints, imageRight, type, free = false, formIdInput='' }) => {
  const buttonText = {
    "bonus": "Download Now",
    "freeGuide": "Download Now",
    "product": "Send Me Lesson #1",
  }
  const formId = {
    "bonus": "7400558",
    "freeGuide": "7400564",
    "product": formIdInput,
  }
  console.log("Name: ", heading);
  console.log("formId: ", formId[type]);
  console.log("------------------------");
  console.log("------------------------");
  console.log("------------------------");
  console.log("------------------------");
  return (
    <Paper
      elevation={3}
      sx={{
        maxWidth: 900,
        mx: 'auto',
        my: 6,
        p: 2,
        borderRadius: '5px',
        backgroundColor: 'white',
      }}
    >
      <Grid
        container
        spacing={2}
        direction={imageRight ? 'row-reverse' : 'row'}
        sx={{
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        {/* Image Section */}
        <Grid item xs={12} md={4} sx={{position: "relative"}}>
          <Box
            component="img"
            src={imageSrc}
            alt="Section Image"
            sx={{
              marginTop: {xs: "0", md: "100px" },
              width: '100%',
              height: 'auto',
              borderRadius: '5px',
              objectFit: 'cover',
            }}
          />
          {free && (
            <Box
              component="img"
              src={freeStamp}
              alt="Section Image"
              sx={{
                backgroundColor: 'blue',
                marginTop: {xs: "0", md: "100px" },
                width: '100%',
                height: "auto",
                borderRadius: '5px',
                objectFit: 'cover',
                position: "relative",
                top: {xs: "0", md:"80px"},
              }}
            />
          )}
        </Grid>
        
        {/* Content Section */}
        <Grid item xs={12} md={7}>
          <H2
            sx={{
              textAlign: "left",
              color: "#141730",
              fontSize: { xs: "24px", lg: "30px" },
              margin: "0 0 20px 0",
              width: "100%",
            }}
          >
            {heading}
          </H2>
          <Typography
            variant="body1"
            paragraph
            sx={{
              color: "#1b2d45",
              fontSize: "15px",
              fontFamily: "Inter, sans, serif !important",
              fontWeight: "500",
            }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <Box component="ul" sx={{ pl: 2 }}>
            {bulletPoints.map((point, index) => (
              <Typography
                key={index}
                component="li"
                variant="body2"
                sx={{
                  color: "#1b2d45",
                  fontSize: "15px",
                  fontWeight: "500",
                  fontFamily: "Inter, sans, serif !important",
                }}
              >
                {point}
              </Typography>
            ))}
          </Box>
          <CTAFormUpdated
            formId={formId[type]}
            buttonText={buttonText[type]}
            width='calc(100%-48px)'
          />
        </Grid>
      </Grid>      
    </Paper>
  );
};
