import React from 'react';
import { Box, Paper, Typography, List, ListItem } from '@mui/material';
import { ProductSection } from './ProductSection';
import { H2, Content, H3 } from '../App'
import { Grid } from '@mui/system';
import { CTAFormUpdated } from './CTAFormUpdated';

export const NewsletterSubscription = () => {
  
  return (
    <Content>
      <H2
        sx={{
          width: "100%",
          marginBottom: "60px",
        }}
      >
        Stay Ahead with Our Free Weekly Academic Success Newsletter
      </H2>
      <Paper
        sx={{
          paddingY: "30px",
          backgroundColor: "white",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginY: "60px",
          alignItems: "center"
        }}
      >
        <H3
          sx={{
            color: "#141730",
            fontSize: { xs: "24px", lg: "30px" },
            margin: {xs: "30px 0 !important", md: "30px 0"},
            width: "80% !important"
          }}
        >
          Ready to take your studies to the next level? Our <strong>Academic Success Newsletter</strong> is designed to help you boost your grades with practical tips, exclusive resources, and proven strategies—all delivered directly to your inbox every week.
        </H3>
        <H3
          sx={{
            color: "#141730",
            fontSize: { xs: "24px", lg: "30px" },
            margin: {xs: "20px 0 !important", md: "20px 0"},
            width: "80% !important",
            textAlign: "left"
          }}
        >
          Here’s what you’ll get each week:  
        </H3>
        <List
          sx={{
          }}
        >
          <ListItem>          
            <p style={{margin: '0'}}>
            •	&nbsp;&nbsp;&nbsp; <b>Expert Study Tips: </b> Actionable advice to improve focus, efficiency, and understanding.
            </p>
            </ListItem>
          <ListItem>            
            <p style={{margin: '0'}}>
            • &nbsp;&nbsp;&nbsp; <b>Writing and Referencing Guides: </b> Clear, step-by-step tutorials to sharpen your academic skills.
            </p>
            </ListItem>
          <ListItem>            
            <p style={{margin: '0'}}>
            • &nbsp;&nbsp;&nbsp; <b>Exclusive Resources and Tools: </b> Downloadable templates, study checklists, and more.
            </p>
          </ListItem>
          <ListItem>
            <p style={{margin: '0'}}>
              • &nbsp;&nbsp;&nbsp; <b>Motivation and Support: </b> Stay on track with a community of like-minded students committed to academic growth.
            </p>            
          </ListItem>
        </List>


        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: "bold",
            marginBottom: 2,
            textAlign: "center",
            margin: "0 auto",
            width: "80%",
            marginTop: "50px !important",
          }}
        >
          Join the Academic Success Newsletter today and make each week a step forward in your academic journey.
        </Typography>
        <CTAFormUpdated
          formId="7400564"
          buttonText="Subscribe Now"
          sx={{width: {xs: '80%', sm: '50%'}}}
        />
      </Paper>
    </Content>
  );
};
