import React from 'react';
import { Box, Paper} from '@mui/material';
import { ProductSection } from './ProductSection';
import { H2, H3, Content } from '../App'

export const ShowCase = ({ heading, images, border = true, children }) => {
  return (
    <Content sx={{ marginBottom: "150px" }}>
      <H2
        sx={{
          width: "100%",
          marginBottom: "60px",
        }}
      >
        {heading}
      </H2>
      <Paper
        sx={{
          paddingY: "30px",
          backgroundColor: "white",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"

        }}
      >
        {children}
        {images.map((image)=>{
          return(
            <Box
              sx={{
                marginY: "10px",
                width: {xs: "95%", md: "80%"},
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: `${ border ? "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" : "none" }`,
              }}
            >
              <img src={image} width={"100%"}/>
            </Box>
          )
        })}
      </Paper>
    </Content>
  );
};
