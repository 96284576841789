import React from 'react';
import { Box, Typography} from '@mui/material';
import { ProductSection } from './ProductSection';
import { H2, Content } from '../App'

import feedback1 from '../assets/images/feedback1.jpg';
import feedback2 from '../assets/images/feedback2.jpg';
import feedback3 from '../assets/images/feedback3.jpg';
import feedback4 from '../assets/images/feedback4.jpg';
import feedback5 from '../assets/images/feedback5.jpg';
import feedback6 from '../assets/images/feedback6.jpg';
import { ShowCase } from './Showcase';

export const Feedback = () => {
  const images = [
    `${feedback1}`,
    `${feedback2}`,
    `${feedback3}`,
    `${feedback4}`,
    `${feedback5}`,
    `${feedback6}`,
  ];
  return (
    <ShowCase
      heading="Receive Personalized Feedback on Your Assessments"
      images={images}
    >
      <Typography
        sx={{
          fontSize: {xs: "1.25rem", md: "1.5rem"},
          textAlign: "center",
          width: {xs: "95%", md: "80%"},
          marginBottom: "50px",
        }}
      >
        Our team provides in-depth, constructive feedback on every assessment to help you improve your writing, develop stronger arguments, and learn from each submission. See real examples of the guidance and support we offer.
      </Typography>
    </ShowCase>
  );
};
