import React from 'react';
import { Box } from '@mui/material';
import { ProductSection } from './ProductSection';
import { H2, Content } from '../App'
import academicWriting from '../assets/images/updated/image06.jpg'
import citations from '../assets/images/updated/image07.jpg'
import plagiarism from '../assets/images/updated/image08.jpg'

export const FreeGuides = () => {
  const guides = [
    {
      imageSrc: `${academicWriting}`,
      heading: '📚 Free Guide 1: Academic Writing Fundamentals',
      content:
        `
          Our Academic Writing Fundamentals Guide is a comprehensive resource designed to help students build a solid foundation in academic writing. This guide is available 100% free and includes:<br/ ><br/ >
          • <strong>Essay Structuring Basics –</strong> Learn how to outline and organize your essays for clarity and coherence.<br /><br />
          • <strong>Thesis Development –</strong> Techniques for crafting a strong thesis that guides your writing and impresses readers.<br /><br />
          • <strong>Argument Building –</strong> Methods to develop convincing arguments that enhance the impact of your work.<br /><br />
          • <strong>Effective Introductions and Conclusions –</strong> Tips to write compelling openings and conclusions that resonate.<br /><br />
          • <strong>Grammar and Style Essentials –</strong> Practical advice on using clear language and avoiding common mistakes.<br /><br />
          And so much more!<br /><br />
          We’re committed to helping you gain confidence and skills in academic writing—no strings attached. Download this guide today and start improving your writing!
        `,
      bulletPoints: [],
    },
    {
      imageSrc: `${citations}`,
      heading: '📖 Free Guide 2: Mastering Referencing and Citations',
      content:
        `
          Our Mastering Referencing and Citations Guide is a practical resource to help you correctly cite sources, ensuring your work is both credible and well-supported. Download it for free to learn:<br /><br />
          • <strong>Citation Styles Made Simple –</strong> Easy-to-follow guides on APA, MLA, Chicago, and more.<br /><br />
          • <strong>Avoiding Common Mistakes –</strong> Tips to sidestep common citation errors that impact grades.<br /><br />
          • <strong>Source Integration Techniques –</strong> Learn how to smoothly integrate quotes and references into your writing.<br /><br />
          • <strong>Creating a Reference Page –</strong> Step-by-step instructions for compiling a flawless reference page.<br /><br />
          • <strong>Understanding In-Text Citations –</strong> Clear guidance on using in-text citations that adhere to academic standards.<br /><br />
          And there’s more!<br /><br />
          Get this free guide to ensure your referencing is accurate and professional—every time. Download now to master citations with confidence!
        `,
      bulletPoints: [],
    },
    {
      imageSrc: `${plagiarism}`,
      heading: '📝 Free Guide 3: Avoiding Plagiarism',
      content:
        `
          Our Avoiding Plagiarism Guide is an essential resource for any student looking to maintain academic integrity and originality in their work. This free guide provides:<br /><br />
          • <strong>Understanding Plagiarism –</strong> What it is, why it matters, and how it affects academic credibility.<br /><br />
          • <strong>Paraphrasing Techniques –</strong> Learn how to express ideas in your own words without losing meaning.<br /><br />
          • <strong>Quoting vs. Paraphrasing –</strong> Understand when to use quotes versus paraphrasing for stronger writing.<br /><br />
          • <strong>Using Sources Responsibly –</strong> Tips for acknowledging ideas and maintaining originality.<br /><br />
          • <strong>Final Checklist for Originality –</strong> A quick checklist to ensure your work is plagiarism-free before submission.<br /><br />
          And much more!<br /><br />
          Download this guide today and take the first step toward confident, original writing. It's completely free—no strings attached!
        `,
      bulletPoints: [],
    },
  ];
  
  return (
    <Content sx={{marginBottom: "150px"}}>
      <H2
        sx={{
          width: "100%",
          marginTop: "150px !important",
          marginBottom: "60px",
        }}
      >
        Free Guides!
      </H2>
      <Box>
        {guides.map((section, index) => (
          <ProductSection
            key={index}
            imageSrc={section.imageSrc}
            heading={section.heading}
            content={section.content}
            bulletPoints={section.bulletPoints}
            imageRight={index % 2 === 0} // Alternate image position
            type="freeGuide"
          />
        ))}
      </Box>
    </Content>
  );
};
