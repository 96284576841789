import React from "react";
import { Box } from "@mui/material";
import { BoostHero } from "../components/BoostHero";
import { Challenges } from "../components/Challenges";
import { FreeGuides } from "../components/FreeGuides";
import { Feedback } from "../components/Feedback";
import { Footer } from "../components/Footer";
import { Chats } from "../components/Chats";
import { NewsletterSubscription } from "../components/NewsletterSubscription";
import { Divider } from "../components/Divider";

export const BoostPage = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#111429",
      }}
      >
      <BoostHero />
      <FreeGuides />
      <Divider />
      <Feedback />
      <Challenges />
      <Chats />
      <NewsletterSubscription />


      <Footer />
    </Box>
  );
}
